import asyncComputed from '../asyncComputed.js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { addRouterGuards } from '../analytics.js';
import { store } from '../store/store.js';
import downloadFileFromUrl from '../helpers/downloadFileFromUrl.js';
import { __ } from '../i18n.js';
import { isPhone } from '../helpers/isPhone.js';
import { MANIFEST as employeeAppManifest } from '../manifest.js';

Vue.use(VueRouter);

export const landingPage = '/auth/employees';
export const employeeLandingPage = '/employee_auth/schedule';

let lastTabIndex = 0;

const routes = [
    route('/login', () => import('../views/Login.vue'), { name: 'userLogin' }),
    route('/forgot_password', () => import('../components/ForgotPassword.vue')),
    route('/reset_password/:token', () =>
        import('../components/ResetPassword.vue')
    ),
    route('/sign_document/:token', () =>
        import('../components/SignDocument.vue')
    ),
    route('/kiosk', () => import('../views/Kiosk.vue'), {
        children: [
            singleTabbedRoute(
                'check_in_out',
                () => __('Check in/out'),
                () => import('../components/Attendance.vue'),
                { meta: { kiosk: true } }
            ),
        ],
    }),
    route('/auth', () => import('../views/Auth.vue'), {
        children: [
            route('personeelsplanning', () =>
                import('../views/PersoneelsplanningLegacy.vue')
            ),
            singleTabbedRoute(
                'actual_schedule',
                () => __('Actual schedule'),
                () => import('../components/ActualScheduleContent.vue')
            ),
            route('pre_planned_schedules', () =>
                import('../views/PrePlannedSchedules.vue')
            ),
            tabbedRoute('employees', () => __('Employees'), {
                '': {
                    icon: 'account-check',
                    title: () => __('Overview'),
                    tooltip: () => __('Overview of your employees'),
                    component: () =>
                        import('../components/EmployeesDashboard.vue'),
                },
                active: {
                    icon: 'account-plus',
                    title: () => __('Active'),
                    titleComponent: () =>
                        import('../components/EmployeesTitle.vue'),
                    tooltip: () =>
                        __('Employees that currently work at your firm'),
                    component: () => import('../components/Employees.vue'),
                    props: { source: 'FIRM_EMPLOYEES' },
                },
                external: {
                    icon: 'account-plus',
                    title: () => __('External'),
                    titleComponent: () =>
                        import('../components/EmployeesTitle.vue'),
                    tooltip: () =>
                        __(
                            'External employees, such as self-employed, or from an employment agency'
                        ),
                    component: () => import('../components/Employees.vue'),
                    props: { source: 'OFFSITE_EMPLOYEES' },
                },
                archive: {
                    icon: 'archive',
                    title: () => __('Archive'),
                    titleComponent: () =>
                        import('../components/EmployeesTitle.vue'),
                    tooltip: () =>
                        __(
                            'Archived employees (they can no longer use the app)'
                        ),
                    component: () => import('../components/Employees.vue'),
                    props: { source: 'ARCHIVED_EMPLOYEES' },
                },
            }),
            tabbedRoute('pools', () => __('Share employees'), {
                '': {
                    icon: 'account-switch',
                    title: () => __('pools'),
                    tooltip: () =>
                        __('Manage pools that share employees between firms'),
                    component: () => import('../components/Pools.vue'),
                },
                internal_invoicing: {
                    icon: 'arrow-collapse',
                    title: () => __('internal invoicing'),
                    tooltip: () =>
                        __(
                            'Monthly overview of the other firms your employees work at'
                        ),
                    component: () =>
                        import('../components/InternalInvoicing.vue'),
                },
            }),
            singleTabbedRoute(
                'attendance',
                () => __('Check in/out'),
                () => import('../components/Attendance.vue')
            ),
            tabbedRoute('hours', () => __('Worked hours'), {
                '': {
                    icon: 'check-all',
                    title: () => __('approved'),
                    tooltip: () =>
                        __('Hours that have been approved by a manager'),
                    component: () => import('../components/ApprovedHours.vue'),
                },
                open: {
                    icon: 'eye-check',
                    title: () => __('open'),
                    tooltip: () =>
                        __('Hours that need to be reviewed by a manager'),
                    component: () => import('../components/OpenHoursTable.vue'),
                },
                insights: {
                    icon: 'chart-histogram',
                    title: () => __('insights'),
                    tooltip: () => __('Show the cost factor per worked hour'),
                    component: () =>
                        import('../components/CostFactorPerPeriod.vue'),
                },
                hours_balance: {
                    icon: 'scale-balance',
                    title: () => __('Hours balance'),
                    tooltip: () => __('Compare total hours and contract hours'),
                    component: () => import('../components/HoursBalance.vue'),
                },
            }),
            tabbedRoute('holiday', () => __('Holidays'), {
                '': {
                    icon: 'scale-balance',
                    title: () => __('balance'),
                    tooltip: () =>
                        __(
                            'The current and historic content of the saved holiday hours'
                        ),
                    component: () => import('../components/HolidayBalance.vue'),
                },
                requests: {
                    icon: 'account-question',
                    title: () => __('requests'),
                    tooltip: () => __('Current and past holiday requests'),
                    component: () =>
                        import('../components/HolidayRequests.vue'),
                },
            }),
            singleTabbedRoute(
                'illness',
                () => __('Illness'),
                () => import('../components/Illness.vue')
            ),
            tabbedRoute('wab_monitor', () => __('WAB monitor'), {
                '': {
                    icon: 'account-details',
                    title: () => __('details'),
                    tooltip: () =>
                        __(
                            'Detailed overview of the different types of hours in relation to the contract hours'
                        ),
                    component: () =>
                        import('../components/PeriodHoursOverview.vue'),
                },
                offers: {
                    icon: 'offer',
                    title: () => __('offers'),
                    tooltip: () => __('Make WAB offers'),
                    component: () =>
                        import('../components/WabOffersOverview.vue'),
                },
            }),
            tabbedRoute('end_of_day', () => __('End of day'), {
                '': {
                    icon: 'finance',
                    title: () => __('Dashboard'),
                    tooltip: () => __('Dashboard of your KPIs'),
                    component: () =>
                        import('../components/EndOfDayAggregate.vue'),
                },
                details: {
                    icon: 'magnify-scan',
                    title: () => __('Details'),
                    tooltip: () => __('Details per day'),
                    component: () =>
                        import('../components/EndOfDayDetailsTable.vue'),
                },
                vault: {
                    icon: 'cash-lock',
                    title: () => __('Vault'),
                    tooltip: () => __('Vault mutations'),
                    component: () => import('../components/RightRequired.vue'),
                    props: {
                        right: 'right_see_vault',
                        component: () => import('../components/Vault.vue'),
                    },
                },
            }),
            tabbedRoute('end_of_month', () => __('End of month'), {
                '': {
                    icon: 'cash-multiple',
                    title: () => __('Salary runs'),
                    tooltip: () =>
                        __('Start salary runs and view their documents'),
                    component: () => import('../components/EndOfMonth.vue'),
                },
                journals: {
                    icon: 'finance',
                    title: () => __('Journals'),
                    tooltip: () => __('Details about your personnel cost'),
                    component: () => import('../components/Journals.vue'),
                },
            }),
            route('peoplecount', () => import('../components/PeopleCount.vue')),
            singleTabbedRoute(
                'contract_templates',
                () => __('Contract templates'),
                () => import('../components/ContractTemplates.vue')
            ),
            //route('dashboard', () => import('../views/Dashboard.vue')),
            singleTabbedRoute(
                'nmbrs',
                () => 'Nmbrs',
                () => import('../components/Nmbrs.vue')
            ),
            tabbedRoute('salary_scales', () => __('Salary scales'), {
                '': {
                    title: () => __('Salary house'),
                    icon: 'cash-refund',
                    tooltip: () =>
                        __(
                            'Salaries per scale, function, and date for your firm'
                        ),
                    component: () => import('../components/SalaryHouse.vue'),
                },
                details: {
                    title: () => __('Details'),
                    icon: 'magnify',
                    tooltip: () =>
                        __(
                            'Details that are used to calculate the salary house'
                        ),
                    component: () =>
                        import('../components/SalaryScalesDetails.vue'),
                },
            }),
            tabbedRoute('sign', () => __('Sign documents'), {
                '': {
                    icon: 'draw',
                    title: () => __('overview'),
                    tooltip: () => __('Show the sign flows that are active'),
                    component: () => import('../components/SignFlows.vue'),
                    props: { isArchived: false, showAdd: true },
                },
                archive: {
                    icon: 'archive',
                    title: () => __('archive'),
                    tooltip: () => __('Show the sign flows that are archived'),
                    component: () => import('../components/SignFlows.vue'),
                    props: { isArchived: true },
                },
                settings: {
                    icon: 'cog',
                    title: () => __('settings'),
                    tooltip: () => __('Change the sign flow settings'),
                    component: () => import('../components/SignSettings.vue'),
                },
            }),
            tabbedRoute('messages', () => __('Messages'), {
                '': {
                    title: () => __('Messages'),
                    icon: 'mdi-inbox',
                    component: () => import('../components/Messages.vue'),
                },
            }),
            route('testbed', () => import('../components/Testbed.vue')),
        ],
    }),
    route('/employee_login', () => import('../components/EmployeeLogin.vue'), {
        name: 'employeeLogin',
        meta: { manifest: employeeAppManifest },
    }),
    route('/employee_auth', () => import('../views/EmployeeAuth.vue'), {
        children: [
            {
                path: '',
                redirect: '/employee_auth/shifts',
            },
            route('avatar', () =>
                import('../components/EmployeeAvatarSelect.vue')
            ),
            route('availabilities', () =>
                import('../components/EmployeeAvailabilities.vue')
            ),
            route('shifts', () => import('../components/EmployeeShifts.vue')),
            route('schedule', () =>
                import('../components/EmployeeSchedule.vue')
            ),
            route('attendances', () =>
                import('../components/EmployeeAttendances.vue')
            ),
            route('check_in_out', () =>
                import('../components/EmployeeCheckInOut.vue')
            ),
            route('payslips', () =>
                import('../components/EmployeePayslips.vue')
            ),
            route('annual_statement', () =>
                import('../components/EmployeeAnnualStatement.vue')
            ),
            route('request_holiday', () =>
                import('../components/EmployeeHolidayRequests.vue')
            ),
            route('holiday_buckets', () =>
                import('../components/EmployeeHolidayBuckets.vue')
            ),
            route('wab_offers', () =>
                import('../components/EmployeeWabOffers.vue')
            ),
            route(
                'firm_documents',
                () => import('../components/FirmDocuments.vue'),
                {
                    props: {
                        getDocuments: () =>
                            store.getters['employeeFirmDocuments/get'](),
                        deleteEndpoint: null,
                        createComponent: null,
                        downloadEndpoint: documentId =>
                            function () {
                                return this.$employeeDownloadFirmDocument({
                                    documentId,
                                }).then(r => downloadFileFromUrl(r.url));
                            },
                    },
                }
            ),
            route('onboarding', () => import('../components/Onboarding.vue'), {
                name: 'employeeOnboarding',
            }),
            route('wage_tax', () =>
                import('../components/EmployeeWageTax.vue')
            ),
            route('*', '/employee_login'),
        ],
        meta: { manifest: employeeAppManifest },
    }),
    route('/new_employee/:token', () =>
        import('../components/NewEmployee.vue')
    ),
    route('/dropbox', () => import('../components/Dropbox.vue')),
    {
        path: '/*',
        redirect: () => (isPhone() ? '/employee_login' : '/login'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    store.commit('kiosk/setMode', !!to.meta.kiosk);
    next();
});

const currentRoute = Vue.observable({
    value: router.currentRoute,
});

router.afterEach(() => {
    currentRoute.value = router.currentRoute;
});

router.afterEach((to, from) => {
    const manifestLink = document.querySelector('#manifest-link');

    const manifest = getManifest(to);

    if (getManifest(from) === manifest) {
        return;
    }

    if (manifest) {
        manifestLink.setAttribute(
            'href',
            URL.createObjectURL(
                new Blob([JSON.stringify(manifest)], {
                    type: 'application/json',
                })
            )
        );
    } else {
        if (manifestLink.href) {
            URL.revokeObjectURL(manifestLink.href);
        }

        manifestLink.removeAttribute('href');
    }
});

function getManifest(route) {
    let result;
    for (const r of route.matched) {
        if (r.meta.manifest) {
            result = r.meta.manifest;
        }
    }
    return result;
}

addRouterGuards(router);

new Vue({
    mixins: [asyncComputed],
    asyncComputed: {
        seeOnlyEndOfDay: {
            handler() {
                return store.getters['right/get']({
                    firm: store.getters.firmInputType,
                    rightName: 'right_see_only_end_of_day',
                });
            },
            default: null,
        },
    },
    computed: {
        shouldRedirect() {
            return (
                this.seeOnlyEndOfDay &&
                currentRoute.value.path !== '/auth/end_of_day'
            );
        },
    },
    watch: {
        shouldRedirect(v) {
            if (v) {
                router.push('/auth/end_of_day');
            }
        },
    },
});

export default router;

function route(path, componentOrRedirect, options = {}) {
    return {
        path,
        [typeof componentOrRedirect === 'string' ? 'redirect' : 'component']:
            componentOrRedirect,
        ...options,
    };
}

function tabbedRoute(path, title, tabs) {
    tabs = Object.entries(tabs).map(([path, tab]) => {
        tab.route = {
            name: `tab${++lastTabIndex}`,
            path: path,
            component: tab.component,
            props: tab.props || {},
            meta: {
                ...tab.meta,
                tab,
            },
        };

        return tab;
    });

    return {
        path,
        component: () => import('../components/TabbedRoute.vue'),
        children: tabs.map(tab => tab.route),
        props: { tabs, title },
    };
}

function singleTabbedRoute(path, title, component, options) {
    return tabbedRoute(path, title, { '': { component, ...options } });
}
