import { render, staticRenderFns } from "./IosInstallPrompt.vue?vue&type=template&id=86681ab8"
import script from "./IosInstallPrompt.vue?vue&type=script&lang=js"
export * from "./IosInstallPrompt.vue?vue&type=script&lang=js"
import style0 from "./IosInstallPrompt.vue?vue&type=style&index=0&id=86681ab8&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../nix/store/zmsnxq0d41hqdxczkn1a6jayyk1cmmwi-cockpit-0.1.0/libexec/cockpit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports