<template>
    <v-app :class="$style.application">
        <div v-if="!isOnLine" class="offline-overlay">
            <p>
                {{ __('You are offline.') }}
                {{
                    __(
                        'Connect to WiFi and make sure your Internet is working to continue using the Cockpit.'
                    )
                }}
            </p>
        </div>
        <router-view />
        <ErrorDialog />
        <Snackbar />
        <PwaInstallPrompt />
        <component
            :is="dialog.component"
            v-for="dialog in dialogs"
            :key="dialog.key"
        />
    </v-app>
</template>

<script>
import ErrorDialog from './components/ErrorDialog.vue';
import { ref, watch, unref } from 'vue';
import { handleError } from './errorReporting';

let lastDialog = 0;

export default {
    name: 'App',
    components: { ErrorDialog },
    data: () => ({
        isOnLine: null,
        dialogs: [],
    }),
    provide() {
        return {
            openDialog: (component, props = {}) => {
                const value = ref(true);
                const dialog = {
                    component: {
                        render: h =>
                            h(component, {
                                props: {
                                    value: value.value,
                                    ...Object.fromEntries(
                                        Object.entries(props).map(([k, v]) => [
                                            k,
                                            unref(v),
                                        ])
                                    ),
                                },
                                on: {
                                    input: v => {
                                        value.value = v;
                                    },
                                },
                            }),
                    },
                    key: ++lastDialog,
                };
                const unwatch = watch(value, (open, wasOpen) => {
                    if (!open && wasOpen) {
                        setTimeout(() => {
                            this.dialogs = this.dialogs.filter(
                                d => d !== dialog
                            );
                        }, 1000);
                        unwatch();
                    }
                });
                this.dialogs = [...this.dialogs, dialog];
                return {
                    close: () => {
                        value.value = false;
                    },
                };
            },
        };
    },
    created() {
        const updateOnLine = () => {
            this.isOnLine =
                window.navigator.onLine &&
                !location.search.match(/(\?|&)TEST_OFFLINE(&|$)/);
        };
        window.addEventListener('online', updateOnLine);
        window.addEventListener('offline', updateOnLine);
        updateOnLine();
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('online', updateOnLine);
            window.removeEventListener('offline', updateOnLine);
        });
    },
    errorCaptured(error) {
        if (handleError(error)) {
            return false;
        }
    },
};
</script>

<style lang="scss">
@import '@mdi/font/css/materialdesignicons.min.css';
.v-application {
    font-family: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.offline-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 22px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
}
.offline-wrapper {
    position: relative;
    z-index: 1;
    height: 100%;
}
table.stats-table {
    width: 100%;
    margin: 15px 0;
    border-spacing: 0;

    td,
    th {
        margin: 0;
        padding: 2px 5px;
        border-bottom: 1px solid #ccc;
    }

    th {
        text-align: left;
    }
}
* {
    scrollbar-color: #005377 transparent;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
    background: #005377;
    border-radius: 5px;
}
.v-dialog:not(.v-dialog--fullscreen) {
    --dialog-max-height: calc(100vh - 48px);
    max-height: var(--dialog-max-height);
}
.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 24px !important;
}
</style>

<style module>
.application > :global(.v-application--wrap) {
    min-height: 100dvh;
}

@media print {
    .application > :global(.v-application--wrap) {
        max-width: none;
    }
    .application :global(.white--text),
    .application :global(.v-tab),
    .application :global(.v-icon) {
        color: black !important;
    }
    .application :global(.primary[class]) {
        color: black !important;
        background: none !important;
    }
}
</style>
